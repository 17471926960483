import {useAuth} from "src/hooks/use-auth";
import {useState} from "react";
import {Box, Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import {Checkbox, FormControlLabel, Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {Trans} from "react-i18next";

const initialValues = {
  emailOrPhone: "",
  agreement: false,
  submit: null,
};

export const Register = () => {
  const {t} = useTranslation();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null); // State for notification message
  const [searchParams, setSearchParams] = useSearchParams();

  const validationSchema = Yup.object({
    agreement: Yup.bool().oneOf([true], "Metin kabul edilmelidir."),
    emailOrPhone: Yup.string()
      .test(
        "emailOrPhone",
        "Geçerli bir telefon numarası (05329993377) girilmelidir.",
        value => {
          const phoneRegex = /^05\d{9}$/;
          return phoneRegex.test(value);
        },
      )
      .required(t("requiredField")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      try {
        setLoading(true);
        const uniqueParameters = {};
        for (const [name, value] of searchParams.entries()) {
          uniqueParameters[name] = value;
        }
        auth.register(values.emailOrPhone, uniqueParameters).then(response => {
          setLoading(false);
          setNotification(t("auth.sentinfo"));
          helpers.setSubmitting(false);
        });
      } catch (err) {
        setLoading(false);
        helpers.setSubmitting(false);
        console.error(err);
      }
    },
  });

  return (
    <>
      {notification ? (
        <Stack sx={{mb: 4}} spacing={2}>
          <Typography style={{whiteSpace: "pre-wrap"}} variant="body2">
            {notification}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            {t("auth.returnToHomepage")}
          </Button>
        </Stack>
      ) : (
        <>
          <Stack sx={{mb: 4}} spacing={1}>
            <Typography variant="h5">{t("auth.register")}</Typography>
          </Stack>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                autoFocus
                error={!!(formik.touched.emailOrPhone && formik.errors.emailOrPhone)}
                fullWidth
                helperText={formik.touched.emailOrPhone && formik.errors.emailOrPhone}
                label={t("auth.phoneNumber")}
                name="emailOrPhone"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.emailOrPhone}
              />
            </Stack>
            <Box sx={{mt: 0}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.agreement}
                    onChange={() => {
                      formik.setFieldValue("agreement", !formik.values.agreement);
                    }}
                    name="agreement"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    <Link
                      href="/documents/register-page/Galata Anket - Katılımcı Ticari Elektronik İleti Onay Metni.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ticari Elektronik İleti Onay Metnini
                    </Link>{" "}
                    okudum ve kabul ediyorum.
                  </Typography>
                }
              />
            </Box>
            <Typography variant="body2">Platformumuz 18 yaş ve üzeri içindir.</Typography>
            <Button
              fullWidth
              disabled={formik.isSubmitting || !formik.isValid}
              sx={{mt: 3}}
              size="large"
              type="submit"
              variant="contained"
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("auth.continue")
              )}
            </Button>
            <Stack sx={{mt: 3, mb: 4}} spacing={3}>
              <Typography underline="hover" variant="subtitle2" color="text.secondary">
                {t("auth.alreadyhaveaccount")}&nbsp;
                <Link href="/auth/login" underline="hover" variant="subtitle2">
                  {t("auth.login")}
                </Link>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Trans
                  i18nKey="auth.registerHelp"
                  components={{
                    email: (
                      <Link href="mailto:anket@galataanket.com" underline="hover">
                        anket@galataanket.com
                      </Link>
                    ),
                  }}
                />
              </Typography>
            </Stack>
          </form>
        </>
      )}
    </>
  );
};
