import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, CircularProgress, MenuItem, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";
import {teamMemberService} from "src/services/team/team-member";
import {seperateChar, TeamMemberBankDetails} from "src/entities/team/team-member";
import {useSettings} from "src/hooks/use-settings";
import {provinces} from "./account-info-user-form";

function isValidTurkishIdNumber(id) {
  if (id.length !== 11 || isNaN(id) || id[0] === "0") {
    return false;
  }
  const digits = id.split("").map(Number);
  const oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
  const evenSum = digits[1] + digits[3] + digits[5] + digits[7];
  const tenthDigit = (oddSum * 7 - evenSum) % 10;
  const eleventhDigit = digits.slice(0, 10).reduce((a, b) => a + b, 0) % 10;

  return digits[9] === tenthDigit && digits[10] === eleventhDigit;
}

function AccountInfoBillingForm() {
  const {t} = useTranslation();
  const settings = useSettings();
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik({
    initialValues: {
      bank_full_name: "John Doe",
      bank_name: t("auth.paymentInfo.bank_name"),
      bank_iban: "",
      ibanConfirmation: "",
      bank_identity_number: "",
      bank_province: "",
      bank_neighborhood: "",
      bank_street: "",
      bank_building: "",
      bank_apartment: "",
      bank_postal_code: "",
    },
    validationSchema: Yup.object({
      bank_full_name: Yup.string().required(t("auth.editInfo.fullNameRequired")),
      bank_name: Yup.string().required(t("auth.paymentInfo.bankNameRequired")),
      bank_iban: Yup.string()
        .required(t("auth.paymentInfo.ibanRequired"))
        .matches(/^TR\d{2}[A-Z0-9]{22}$/, t("auth.paymentInfo.invalidIban")),
      ibanConfirmation: Yup.string()
        .required(t("auth.paymentInfo.ibanConfirmationRequired"))
        .oneOf([Yup.ref("bank_iban"), null], t("auth.paymentInfo.ibansNotMatch")),
      bank_identity_number: Yup.string()
        .required(t("auth.editInfo.identityNumberRequired"))
        .test(
          "valid-tc",
          t("auth.editInfo.invalidIdentityNumber"),
          isValidTurkishIdNumber,
        ),
      bank_province: Yup.string().required(t("auth.paymentInfo.cityRequired")),
      bank_neighborhood: Yup.string().required(
        t("auth.paymentInfo.neighborhoodRequired"),
      ),
      bank_street: Yup.string().required(t("auth.paymentInfo.streetRequired")),
      bank_building: Yup.string().required(t("auth.paymentInfo.buildingRequired")),
      bank_apartment: Yup.string().required(t("auth.paymentInfo.apartmentRequired")),
      bank_postal_code: Yup.string()
        .required(t("auth.paymentInfo.postalCodeRequired"))
        .matches(/^\d{5}$/, t("auth.paymentInfo.invalidPostalCode")),
    }),

    onSubmit: async values => {
      try {
        values.bank_building =
          values.bank_building + seperateChar + values.bank_apartment;

        const teamMemberBankDetailsUpdateData = new TeamMemberBankDetails(values);

        await teamMemberService
          .saveBankDetails(teamMemberBankDetailsUpdateData)
          .then(() => {
            getBankDetails().then(() => {
              setIsEditing(false);
              settings.showAlert("success", t("successfullyUpdated"));
            });
          });
      } catch (err) {
        settings.showAlert("error", "Failed to save ");
        console.error("Failed to save user data:", err);
      }
    },
  });

  const {setFieldValue, handleSubmit} = formik;

  const getBankDetails = async () => {
    try {
      const bankInfo = new TeamMemberBankDetails(
        await teamMemberService.getBankDetails(),
      );

      setFieldValue("bank_full_name", bankInfo?.bank_full_name || "");
      setFieldValue("bank_name", bankInfo?.bank_name || "");
      setFieldValue("bank_iban", bankInfo?.bank_iban || "");
      setFieldValue("ibanConfirmation", bankInfo?.bank_iban || "");
      setFieldValue("bank_identity_number", bankInfo?.bank_identity_number || "");
      setFieldValue("bank_province", bankInfo?.bank_province || "");
      setFieldValue("bank_neighborhood", bankInfo?.bank_neighborhood || "");
      setFieldValue("bank_street", bankInfo?.bank_street || "");
      const [apartmentInfo, flatInfo] = bankInfo?.bank_building?.split(seperateChar) || [
        bankInfo?.bank_building,
        "",
      ];
      setFieldValue("bank_building", apartmentInfo || "");
      setFieldValue("bank_apartment", flatInfo || "");
      setFieldValue("bank_postal_code", bankInfo?.bank_postal_code || "");

      setLoading(false);
    } catch (err) {
      console.error("Failed to fetch team member info:", err);
    }
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        m: 3,
        mt: 0,
      }}
    >
      <Typography variant="body2">{t("auth.paymentInfo.paymentAlert")}</Typography>

      {loading ? (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {/* Full Name */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.editInfo.bank_full_name")}
            name="bank_full_name"
            value={formik.values.bank_full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_full_name && Boolean(formik.errors.bank_full_name)}
            helperText={formik.touched.bank_full_name && formik.errors.bank_full_name}
            disabled={!isEditing}
          />
          {/* Bank Name */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.bank_name")}
            name="bank_name"
            value={formik.values.bank_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
            helperText={formik.touched.bank_name && formik.errors.bank_name}
            disabled={!isEditing}
          />
          {/* IBAN */}
          <TextField
            sx={{width: "100%"}}
            label="IBAN (TRXXXXXXXXXXXXXXXXXXXXXX)"
            name="bank_iban"
            value={formik.values.bank_iban}
            onChange={event => {
              const newValue = event.target.value.replace(/\s+/g, "");
              formik.setFieldValue("bank_iban", newValue);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_iban && Boolean(formik.errors.bank_iban)}
            helperText={formik.touched.bank_iban && formik.errors.bank_iban}
            disabled={!isEditing}
          />

          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.ibanConfirmation")}
            name="ibanConfirmation"
            value={formik.values.ibanConfirmation}
            onChange={event => {
              const newValue = event.target.value.replace(/\s+/g, "");
              formik.setFieldValue("ibanConfirmation", newValue);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.ibanConfirmation && Boolean(formik.errors.ibanConfirmation)
            }
            helperText={formik.touched.ibanConfirmation && formik.errors.ibanConfirmation}
            disabled={!isEditing}
          />
          {/* Identity Number */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.editInfo.bank_identity_number")}
            name="bank_identity_number"
            value={formik.values.bank_identity_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.bank_identity_number &&
              Boolean(formik.errors.bank_identity_number)
            }
            helperText={
              formik.touched.bank_identity_number && formik.errors.bank_identity_number
            }
            disabled={!isEditing}
          />
          {/* Address Fields */}
          {/* Şehir */}
          <Typography variant="body2">{t("auth.editInfo.bank_address")}</Typography>

          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.city")}
            disabled={!isEditing}
            error={formik.touched.bank_province && Boolean(formik.errors.bank_province)}
            helperText={formik.touched.bank_province && formik.errors.bank_province}
            name="bank_province"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bank_province}
            select
          >
            {provinces.map(province => (
              <MenuItem key={province.code} value={province.name}>
                {province.code} - {province.name}
              </MenuItem>
            ))}
          </TextField>

          {/* Mahalle */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.neighborhood")}
            name="bank_neighborhood"
            value={formik.values.bank_neighborhood}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.bank_neighborhood && Boolean(formik.errors.bank_neighborhood)
            }
            helperText={
              formik.touched.bank_neighborhood && formik.errors.bank_neighborhood
            }
            disabled={!isEditing}
          />

          {/* Sokak */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.street")}
            name="bank_street"
            value={formik.values.bank_street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_street && Boolean(formik.errors.bank_street)}
            helperText={formik.touched.bank_street && formik.errors.bank_street}
            disabled={!isEditing}
          />

          {/* Apartman */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.building")}
            name="bank_building"
            value={formik.values.bank_building}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_building && Boolean(formik.errors.bank_building)}
            helperText={formik.touched.bank_building && formik.errors.bank_building}
            disabled={!isEditing}
          />

          {/* Daire */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.apartment")}
            name="bank_apartment"
            value={formik.values.bank_apartment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_apartment && Boolean(formik.errors.bank_apartment)}
            helperText={formik.touched.bank_apartment && formik.errors.bank_apartment}
            disabled={!isEditing}
          />

          {/* Posta Kodu */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.postalCode")}
            name="bank_postal_code"
            value={formik.values.bank_postal_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.bank_postal_code && Boolean(formik.errors.bank_postal_code)
            }
            helperText={formik.touched.bank_postal_code && formik.errors.bank_postal_code}
            disabled={!isEditing}
          />
          {/* Submit Button */}
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              paddingTop: 1,
              paddingBottom: 1,
              zIndex: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{backgroundColor: "#ad1445", width: "100%"}}
              onClick={isEditing ? handleSubmit : () => setIsEditing(true)}
              type={isEditing ? "submit" : "button"}
            >
              {isEditing
                ? t("auth.editInfo.saveAccountInformation")
                : t("auth.editInfo.editAccountInformation")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AccountInfoBillingForm;
