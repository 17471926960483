import axiosInstance from "src/services/axiosInstance";

class ProjectService {
  async fetchProjectBlockQuestions(projectId, elementId) {
    return await axiosInstance
      .get(
        `/projects/${projectId}/questions${elementId ? `?element_id=${elementId}` : ""}`,
      )
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async fetchProjectDetails(uuid, token) {
    const query_params = {};
    if (token) {
      query_params["assignment_token"] = token;
    }
    return await axiosInstance
      .get(`/projects/${uuid}`, {params: query_params})
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async listProjectAssignments(status, page) {
    return await axiosInstance
      .get("/projects", {params: {status: status, page: page}})
      .then(projects => {
        return projects?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const projectService = new ProjectService();
