import React from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {useFormik} from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import {useSettings} from "src/hooks/use-settings";
import {useTranslation} from "react-i18next";

const ForResearchers = () => {
  const {t} = useTranslation();
  const settings = useSettings();

  const formik = useFormik({
    initialValues: {
      contact_firstname: "",
      contact_surname: "",
      contact_email: "",
      subject: "",
      summary: "",
      targetAudience: "",
      participantCount: "",
      questionCount: "",
      plannedDate: "",
      additionalDetails: "",
      referenceEmail: "",
    },
    validationSchema: Yup.object({
      contact_firstname: Yup.string()
        .required(t("forResearchersPage.firstnameRequired"))
        .matches(/^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]+$/, t("forResearchersPage.onlyLetters")),
      contact_surname: Yup.string()
        .required(t("forResearchersPage.surnameRequired"))
        .matches(/^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]+$/, t("forResearchersPage.onlyLetters")),
      subject: Yup.string().required(t("forResearchersPage.subjectRequired")),
      summary: Yup.string().required(t("forResearchersPage.summaryRequired")),
      targetAudience: Yup.string().required(
        t("forResearchersPage.targetAudienceRequired"),
      ),
      participantCount: Yup.number()
        .typeError(t("forResearchersPage.numberRequired"))
        .required(t("forResearchersPage.participantCountRequired")),
      questionCount: Yup.number()
        .typeError(t("forResearchersPage.numberRequired"))
        .required(t("forResearchersPage.questionCountRequired")),
      plannedDate: Yup.date().required(t("forResearchersPage.plannedDateRequired")),
      additionalDetails: Yup.string(),
      contact_email: Yup.string().required(t("forResearchersPage.emailRequired")),
    }),
    onSubmit: (values, {setSubmitting, resetForm}) => {
      const formattedValues = {
        contact_firstname: values.contact_firstname,
        contact_surname: values.contact_surname,
        contact_email: values.contact_email,
        reply_To: values.email,
        message: `
                    Araştırma Konusu: ${values.subject}

                    Araştırma Özeti: ${values.summary}

                    Hedef Kitle: ${values.targetAudience}

                    Katılımcı Sayısı: ${values.participantCount}

                    Soru Sayısı: ${values.questionCount}

                    Planlanan Tarih: ${values.plannedDate}

                    Ek Detaylar: ${values.additionalDetails || "Belirtilmedi"}

                    Referans E-posta: ${values.referenceEmail || "Belirtilmedi"}
                `,
      };
      emailjs
        .send("service_exohkma", "template_j0e7d3a", formattedValues, "A2pgTFc93Cu0aXiC3")
        .then(() => {
          settings.showAlert("success", "Başvurunuz başarıyla gönderildi!");
          resetForm();
        })
        .catch(() => {
          settings.showAlert("error", "Gönderme sırasında bir hata oluştu!");
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <Box
      sx={{
        minHeight: "80svh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 3,
      }}
    >
      <Card sx={{width: "100%", maxWidth: 1200, boxShadow: 3}}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            {/* Box */}
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{textAlign: {xs: "center", md: "left"}}}
            >
              <Box sx={{mb: 3}}>
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{fontWeight: "bold", mb: 1, textAlign: "center"}}
                >
                  {t("forResearchersPage.contactTitle")}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{textAlign: "center"}}
                >
                  {t("forResearchersPage.description")}
                </Typography>
              </Box>
              <Box sx={{mt: 4}}>
                <ul style={{listStyleType: "none", padding: 0}}>
                  <li
                    style={{display: "flex", alignItems: "center", marginBottom: "16px"}}
                  >
                    <Box
                      sx={{
                        minWidth: 36,
                        minHeight: 36,
                        borderRadius: "50%",
                        backgroundColor: "primary.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{color: "white", fontWeight: "bold"}}
                      >
                        1
                      </Typography>
                    </Box>
                    <div>
                      <Typography variant="h7" sx={{fontWeight: "bold"}}>
                        {t("forResearchersPage.step1Title")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {t("forResearchersPage.step1Description")}
                      </Typography>
                    </div>
                  </li>
                  <li
                    style={{display: "flex", alignItems: "center", marginBottom: "16px"}}
                  >
                    <Box
                      sx={{
                        minWidth: 36,
                        minHeight: 36,
                        borderRadius: "50%",
                        backgroundColor: "primary.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{color: "white", fontWeight: "bold"}}
                      >
                        2
                      </Typography>
                    </Box>
                    <div>
                      <Typography variant="h7" sx={{fontWeight: "bold"}}>
                        {t("forResearchersPage.step2Title")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {t("forResearchersPage.step2Description")}
                      </Typography>
                    </div>
                  </li>
                  <li
                    style={{display: "flex", alignItems: "center", marginBottom: "16px"}}
                  >
                    <Box
                      sx={{
                        minWidth: 36,
                        minHeight: 36,
                        borderRadius: "50%",
                        backgroundColor: "primary.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{color: "white", fontWeight: "bold"}}
                      >
                        3
                      </Typography>
                    </Box>
                    <div>
                      <Typography variant="h7" sx={{fontWeight: "bold"}}>
                        {t("forResearchersPage.step3Title")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {t("forResearchersPage.step3Description")}
                      </Typography>
                    </div>
                  </li>
                  <li
                    style={{display: "flex", alignItems: "center", marginBottom: "16px"}}
                  >
                    <Box
                      sx={{
                        minWidth: 36,
                        minHeight: 36,
                        borderRadius: "50%",
                        backgroundColor: "primary.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{color: "white", fontWeight: "bold"}}
                      >
                        4
                      </Typography>
                    </Box>
                    <div>
                      <Typography
                        variant="h7"
                        sx={{fontWeight: "bold", textAlign: "start"}}
                      >
                        {t("forResearchersPage.step4Title")}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{maxWidth: 380}}
                      >
                        {t("forResearchersPage.step4Description")}
                      </Typography>
                    </div>
                  </li>
                </ul>
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{mt: 1, mb: 5}}>
                {t("forResearchersPage.moreQuestions")}{" "}
                <Typography
                  component="a"
                  href="mailto:arastirma@galataanket.com"
                  sx={{color: "primary.main", textDecoration: "none"}}
                >
                  arastirma@galataanket.com
                </Typography>
              </Typography>
            </Grid>

            {/* Table */}
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{fontWeight: "bold", color: "primary.main"}}
              >
                {t("forResearchersPage.shareInfo")}
              </Typography>
              <form
                onSubmit={formik.handleSubmit}
                style={{width: "100%", maxWidth: "400px"}}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label={t("forResearchersPage.firstName")}
                      name="contact_firstname"
                      value={formik.values.contact_firstname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      error={
                        formik.touched.contact_firstname &&
                        Boolean(formik.errors.contact_firstname)
                      }
                      helperText={
                        formik.touched.contact_firstname &&
                        formik.errors.contact_firstname
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label={t("forResearchersPage.lastName")}
                      name="contact_surname"
                      value={formik.values.contact_surname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      error={
                        formik.touched.contact_surname &&
                        Boolean(formik.errors.contact_surname)
                      }
                      helperText={
                        formik.touched.contact_surname && formik.errors.contact_surname
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("forResearchersPage.email")}
                      name="contact_email"
                      value={formik.values.contact_email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      error={
                        formik.touched.contact_email &&
                        Boolean(formik.errors.contact_email)
                      }
                      helperText={
                        formik.touched.contact_email && formik.errors.contact_email
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("forResearchersPage.researchSubject")}
                      name="subject"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("forResearchersPage.researchSummary")}
                      name="summary"
                      value={formik.values.summary}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      multiline
                      rows={3}
                      variant="outlined"
                      error={formik.touched.summary && Boolean(formik.errors.summary)}
                      helperText={formik.touched.summary && formik.errors.summary}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("forResearchersPage.targetAudience")}
                      name="targetAudience"
                      value={formik.values.targetAudience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      error={
                        formik.touched.targetAudience &&
                        Boolean(formik.errors.targetAudience)
                      }
                      helperText={
                        formik.touched.targetAudience && formik.errors.targetAudience
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label={t("forResearchersPage.targetParticipants")}
                      name="participantCount"
                      type="number"
                      value={formik.values.participantCount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      error={
                        formik.touched.participantCount &&
                        Boolean(formik.errors.participantCount)
                      }
                      helperText={
                        formik.touched.participantCount && formik.errors.participantCount
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label={t("forResearchersPage.plannedQuestions")}
                      name="questionCount"
                      type="number"
                      value={formik.values.questionCount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      error={
                        formik.touched.questionCount &&
                        Boolean(formik.errors.questionCount)
                      }
                      helperText={
                        formik.touched.questionCount && formik.errors.questionCount
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("forResearchersPage.plannedDate")}
                      name="plannedDate"
                      type="date"
                      value={formik.values.plannedDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      required
                      variant="outlined"
                      InputLabelProps={{shrink: true}} // Çakışmayı önler
                      error={
                        formik.touched.plannedDate && Boolean(formik.errors.plannedDate)
                      }
                      helperText={formik.touched.plannedDate && formik.errors.plannedDate}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("forResearchersPage.additionalDetails")}
                      name="additionalDetails"
                      value={formik.values.additionalDetails}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      multiline
                      rows={3}
                      variant="outlined"
                      error={
                        formik.touched.additionalDetails &&
                        Boolean(formik.errors.additionalDetails)
                      }
                      helperText={
                        formik.touched.additionalDetails &&
                        formik.errors.additionalDetails
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("forResearchersPage.referenceEmail")}
                      name="referenceEmail"
                      value={formik.values.referenceEmail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={formik.isSubmitting}
                      endIcon={
                        formik.isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          <SendIcon />
                        )
                      }
                      sx={{mt: 2, py: 1.5, fontWeight: "bold", textTransform: "none"}}
                    >
                      {formik.isSubmitting
                        ? t("forResearchersPage.Sending")
                        : t("forResearchersPage.submitInfo")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                {t("forResearchersPage.moreInfo")}{" "}
                <Typography
                  component="a"
                  href="mailto:arastirma@galataanket.com"
                  sx={{color: "primary.main", textDecoration: "none"}}
                >
                  arastirma@galataanket.com
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForResearchers;
