import {createContext, useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {submissionService} from "src/services/project/submission";
import {projectService} from "src/services/project/project";
import {Block, Survey} from "src/entities/project/survey/survey";
import {Submission} from "src/entities/project/survey/submission";

export const SurveyEngineContext = createContext({
  survey: null,
  submission: null,
  remainingQuestionCount: 0,
});

export const SurveyEngineProvider = props => {
  const {children, submissionStatus} = props;
  const {uuid} = useParams();
  const [error, setError] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [inaccessible, setInaccessible] = useState(null);
  const [submission, setSubmission] = useState(null);
  const [remainingQuestionCount, setRemainingQuestionCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    handleInitialize();
  }, []);

  const handleInitialize = async _ => {
    const surveyData = await getSurveyDetails();
    if (surveyData === null) {
      setInaccessible(true);
      return;
    }

    if (surveyData.is_outsource) {
      setSurvey(surveyData);
      return;
    }

    const submissionData = await handleInitializeSubmission();
    if (submissionData === null) {
      setError(true);
      return;
    }
    setSubmission(submissionData);

    const projectBlockQuestionsRequest = projectService.fetchProjectBlockQuestions(uuid);
    const minimumLoadingTime = new Promise(resolve => setTimeout(resolve, 3000));
    const [projectBlockQuestionsResponse] = await Promise.all([
      projectBlockQuestionsRequest,
      minimumLoadingTime,
    ]);
    setRemainingQuestionCount(projectBlockQuestionsResponse.remaining_question_count);
    const block = new Block(projectBlockQuestionsResponse.results);
    surveyData.extendAllQuestions(block);
    surveyData.setCurrentBlock(block);
    surveyData.setTotalQuestionsCount(
      projectBlockQuestionsResponse.remaining_question_count,
    );
    surveyData.setNextFlowElementId(projectBlockQuestionsResponse.next_flow_element_id);
    setSurvey(surveyData);
  };

  const getNameValueParameters = _ => {
    const uniqueParameters = [];
    for (const [name, value] of searchParams.entries()) {
      uniqueParameters[name] = value;
    }
    return Object.keys(uniqueParameters).map(name => ({
      name: name,
      value: uniqueParameters[name],
    }));
  };

  const handleInitializeSubmission = async _ => {
    const data = {
      project_id: uuid,
      assigment_token: searchParams.get("token"),
      parameters: getNameValueParameters(),
    };
    try {
      const submission = await submissionService.getOrCreateSubmission(data);
      return new Submission(submission);
    } catch (e) {
      return null;
    }
  };

  const getSurveyDetails = async _ => {
    try {
      const project = await projectService.fetchProjectDetails(
        uuid,
        searchParams.get("token"),
      );
      return new Survey(project);
    } catch (e) {
      return null;
    }
  };

  const handleGetNextBlock = async elementId => {
    await projectService
      .fetchProjectBlockQuestions(uuid, elementId)
      .then(projectBlockQuestionsResponse => {
        const block = new Block(projectBlockQuestionsResponse.results);
        survey.extendAllQuestions(block);
        survey.setCurrentBlock(block);
        survey.setNextFlowElementId(projectBlockQuestionsResponse.next_flow_element_id);
        setSurvey(survey);
        setRemainingQuestionCount(projectBlockQuestionsResponse.remaining_question_count);
      });
  };

  const handleQuestionAnswer = (question, answer, extra) => {
    const prevAnswer = submission.answersMap[question.id] ?? null;
    const answerData = {
      id: prevAnswer ? prevAnswer.id : null,
      text: answer,
      question_id: question.id,
      embedded_text: null,
      page_id: question.page_id,
      ...extra,
    };
    submission.extendAnswers([answerData]);
    setSubmission(submission);
  };

  const handlePageAnswers = async (pageQuestionIds, surveyParameters) => {
    const data = {
      status: submissionStatus,
      answers: Object.values(submission.answersMap).filter(answer =>
        pageQuestionIds.includes(answer.question),
      ),
      parameters: surveyParameters,
    };
    const answerResponse = await submissionService.submitPageAnswers(uuid, data);
    setRemainingQuestionCount(remainingQuestionCount - pageQuestionIds.length);
  };

  const handleCompleteSubmission = () => {
    submissionService.completeSubmission(uuid);
  };

  const handleCompleteOutsourceProject = async queryParams => {
    await submissionService.completeOutsourceProject(uuid, queryParams);
  };

  return (
    <SurveyEngineContext.Provider
      value={{
        survey,
        submission,
        remainingQuestionCount,
        error,
        inaccessible,

        handleGetNextBlock,
        handleQuestionAnswer,
        handlePageAnswers,
        handleCompleteSubmission,
        handleCompleteOutsourceProject,
      }}
    >
      {children}
    </SurveyEngineContext.Provider>
  );
};

export const SurveyEngineConsumer = SurveyEngineContext.Consumer;
