import {Box} from "@mui/system";
import {Button, Card, CardContent, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useSurveyEngine} from "src/hooks/use-survey-engine";
import {useRouter} from "src/hooks/use-router";
import {GifSplashScreen} from "src/components/gif-splash-screen";

export const CompleteOutsourceSurvey = () => {
  const {t} = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const surveyEngine = useSurveyEngine();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const uniqueParameters = {};
    for (const [name, value] of searchParams.entries()) {
      uniqueParameters[name] = value;
    }
    const queryParams = {
      token: uniqueParameters["token"],
      status: uniqueParameters["st"],
    };
    surveyEngine
      .handleCompleteOutsourceProject(queryParams)
      .then(_ => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <GifSplashScreen />;
  }

  return (
    <Box sx={{px: "5%"}}>
      <Card>
        <CardContent>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{
              py: 2, // Dikey padding
              fontSize: {xs: "1rem", sm: "1.5rem", md: "2rem"}, // Ekran boyutuna göre yazı boyutu
            }}
          >
            {t("defaultEndPageMessage")}
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            padding={4}
            gap={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => router.push("/dashboard/open-surveys")}
              sx={{width: "100%", maxWidth: "300px"}}
            >
              {t("goToMySurveys")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
