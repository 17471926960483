import React, {useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Grid, LinearProgress} from "@mui/material";
import {useTranslation} from "react-i18next";
import {teamMemberService} from "src/services/team/team-member";
import {
  TeamMemberBankDetails,
  TeamMemberRewardRequest,
  TeamMemberRewardsStats,
} from "src/entities/team/team-member";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import {useSettings} from "src/hooks/use-settings";
import {rewardRequestStatus, rewardType} from "src/schemas/project/reward";
import CancelRewardButtonAndDialog from "./cancel-reward-button-and-dialog";
import GenericTablePagination from "src/components/generic/GenericTablePagination";
import GenericCard from "src/components/generic/GenericCard";
import {isMobile as isMobile2} from "react-device-detect";
import {datetimeToObject} from "src/utils/datetime";
import {getBackgroundColor} from "src/utils";

export const Rewards = () => {
  const {t} = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();
  const settings = useSettings();

  const [loading, setLoading] = useState(false);
  // Rewards
  const [rewardStats, setRewardStats] = useState(new TeamMemberRewardsStats({}));
  const [rewardRequests, setRewardRequests] = useState([]);
  const [totalRewardRequestCount, setTotalRewardRequestCount] = useState(0);
  const [rewardRequestPage, setRewardRequestPage] = useState(1);

  // Migros
  const [migrosButtonLoading, setMigrosButtonLoading] = useState(false);
  const [selectedMigrosAmount, setSelectedMigrosAmount] = useState(null);

  // A101
  const [a101ButtonLoading, setA101ButtonLoading] = useState(false);
  const [selectedA101Amount, setSelectedA101Amount] = useState(null);

  // IBAN
  const [ibanAmount, setIbanAmount] = useState("");
  const [ibanButtonLoading, setIbanButtonLoading] = useState(false);
  const [isIbanModalOpen, setIsIbanModalOpen] = useState(false);
  const [bankAccountValidationCheckBox, setBankAccountValidationCheckBox] =
    useState(false);
  const [bankInfoValidationCheckBox, setBankInfoValidationCheckBox] = useState(false);
  const [transactionAccountValidationCheckbox, setTransactionAccountValidationCheckbox] =
    useState(false);
  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);

  useEffect(() => {
    teamMemberService.getTeamMemberRewardsStats().then(stats => {
      setRewardStats(new TeamMemberRewardsStats(stats));
    });
    teamMemberService.getBankDetails().then(response => {
      const details = new TeamMemberBankDetails(response);
      setBankDetails(details);
    });
    handleRewardRequests(rewardRequestPage);
  }, []);

  const handleRewardRequests = page => {
    setLoading(true);
    setRewardRequestPage(page);
    teamMemberService.listTeamMemberRewardRequests(page).then(response => {
      const requests = response.results.map(req => new TeamMemberRewardRequest(req));
      setLoading(false);
      setTotalRewardRequestCount(response.count);
      setRewardRequests(requests);
    });
  };

  const handleNavigateToHowToUseGiftCodes = () => {
    router.push(paths.howtousegiftcodes);
  };

  const handleNavigateToBankDetails = () => {
    router.push(paths.dashboard.accountInfo, {state: {drawerOpen: "payment"}});
  };

  const handleReceiveGift = (rewardType, amount, setIsLoading) => {
    setIsLoading(true);
    const payload = {
      amount: amount,
      type: rewardType,
    };
    settings.showAlert("success", "Talebiniz alınmıştır!");
    teamMemberService.createTeamMemberRewardRequests(payload).then(response => {
      setIsLoading(false);
      rewardStats.decrementAvailableRewardAmount(amount);
      setRewardStats(rewardStats.clone());
      setSelectedA101Amount(null);
      setSelectedMigrosAmount(null);
      handleRewardRequests(rewardRequestPage);
    });
  };

  const handleIbanChange = event => {
    setIbanAmount(event.target.value);
  };

  const selectAmount = (setSelectedAmount, amount) => {
    if (rewardStats.available_reward_amount >= amount) {
      setSelectedAmount(amount);
    }
  };

  const handleOpenIbanModal = () => {
    setIsIbanModalOpen(true);
  };

  const handleCloseIbanModal = () => {
    setIsIbanModalOpen(false);
    setBankAccountValidationCheckBox(false);
    setBankInfoValidationCheckBox(false);
    setTransactionAccountValidationCheckbox(false);
  };

  const handleIbanConfirm = () => {
    handleReceiveGift(rewardType.CASH, ibanAmount, setIbanButtonLoading);
    handleCloseIbanModal();
  };

  const getRequestedStatusText = status => {
    switch (status) {
      case rewardRequestStatus.PENDING:
        return t("rewards.processing");
      case rewardRequestStatus.APPROVED:
        return t("rewards.approved");
      case rewardRequestStatus.REJECTED:
        return t("rewards.rejected");
      case rewardRequestStatus.CANCELLED:
        return t("rewards.cancelled");
      default:
        return "-";
    }
  };

  const getGiftCode = (type, code) => {
    switch (type) {
      case rewardType.A101:
        return code?.split("|")[0];
      default:
        return code ? code : "";
    }
  };

  const getGiftPin = (type, code) => {
    switch (type) {
      case rewardType.A101:
        return code?.split("|")[1];
      default:
        return code ? t("rewards.notRequired") : "";
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        {/* Points Boxes */}
        <Grid container spacing={isMobile ? 2 : 4} sx={{mb: 4}}>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.availablePoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.available_reward_amount}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.pendingPoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.pending_reward_amount}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.usedPoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.used_reward_amount}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Sağ hizalama
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              mb: 2,
            }}
            onClick={() => setIsExplanationModalOpen(true)} // Popup açma
            color="primary"
          >
            {t("Eksik Puanın Olduğunu Mu Düşünüyorsun?")}
          </Button>
        </Box>

        {/* Eksik Puanlar Açıklama Popup */}
        <Modal
          open={isExplanationModalOpen}
          onClose={() => setIsExplanationModalOpen(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: "center",
                fontWeight: "bold",
                color: "primary.main",
              }}
            >
              Eksik Puanlar Hakkında
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 2,
                textAlign: "left",
                color: "text.secondary",
              }}
            >
              Eksik puanlarınız birkaç sebepten kaynaklanıyor olabilir:
            </Typography>
            <Box sx={{textAlign: "left"}}>
              <ul>
                <li>
                  <Typography variant="body2">
                    Algoritmamız, soruları tam olarak okumadığınızı tespit etmiş olabilir.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Cevaplama süresinin kısa olması, doğru bir değerlendirme yapılmasına
                    engel olmuş olabilir.
                  </Typography>
                </li>
              </ul>
            </Box>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                bgcolor: "primary.main",
                color: "white",
                "&:hover": {
                  bgcolor: "primary.dark",
                },
              }}
              onClick={() => setIsExplanationModalOpen(false)} // Popup kapatma
            >
              {t("Kapat")}
            </Button>
          </Box>
        </Modal>

        {/* Rewards Section */}
        <Box sx={{mb: 2}}>
          <Typography variant="h5" sx={{width: "100%"}}>
            {t("rewards.rewards")}
          </Typography>
          <Typography variant="body2">{t("rewards.giftCardDescription")}</Typography>
          <Typography variant="body2">{t("rewards.giftCardTaxDescription")}</Typography>
          <Typography variant="body2">{t("rewards.giftLimitDescription")}</Typography>
        </Box>

        <Button onClick={handleNavigateToHowToUseGiftCodes} color="primary">
          {t("Hediye Kodları Nasıl Kullanılır?")}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "space-between",
            gap: 2,
            mb: 4,
          }}
        >
          {/* Migros Card */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: isMobile ? "100%" : "30%",
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">Migros {t("rewards.giftCard")}</Typography>
            <Box sx={{display: "flex", justifyContent: "center", gap: 1, mt: 2}}>
              {[50, 100, 200].map(amount => (
                <Button
                  key={amount}
                  variant={selectedMigrosAmount === amount ? "contained" : "outlined"}
                  onClick={() => selectAmount(setSelectedMigrosAmount, amount)}
                  disabled={rewardStats.available_reward_amount < amount}
                >
                  {amount}
                </Button>
              ))}
            </Box>
            <LoadingButton
              disabled={
                selectedMigrosAmount === null ||
                rewardStats.available_reward_amount < selectedMigrosAmount
              }
              loading={migrosButtonLoading}
              variant="contained"
              onClick={() =>
                handleReceiveGift(
                  rewardType.MIGROS,
                  selectedMigrosAmount,
                  setMigrosButtonLoading,
                )
              }
              sx={{mt: 2}}
            >
              {t("rewards.receiveGifts")}
            </LoadingButton>
          </Box>
          {/* A101 Card */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: isMobile ? "100%" : "30%",
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">A101 {t("rewards.giftCard")}</Typography>
            <Box sx={{display: "flex", justifyContent: "center", gap: 1, mt: 2}}>
              {[50, 100, 200, 300].map(amount => (
                <Button
                  key={amount}
                  variant={selectedA101Amount === amount ? "contained" : "outlined"}
                  onClick={() => selectAmount(setSelectedA101Amount, amount)}
                  disabled={rewardStats.available_reward_amount < amount}
                >
                  {amount}
                </Button>
              ))}
            </Box>
            <LoadingButton
              disabled={
                selectedA101Amount === null ||
                rewardStats.available_reward_amount < selectedA101Amount
              }
              loading={a101ButtonLoading}
              variant="contained"
              onClick={() =>
                handleReceiveGift(
                  rewardType.A101,
                  selectedA101Amount,
                  setA101ButtonLoading,
                )
              }
              sx={{mt: 2}}
            >
              {t("rewards.receiveGifts")}
            </LoadingButton>
          </Box>

          {/* Iban Payment Section */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              mx: "auto",
              width: isMobile ? "100%" : "30%",
            }}
          >
            <Typography variant="h6">{t("rewards.sendToIban")}</Typography>
            <TextField
              sx={{mt: 2, width: "100%"}}
              disabled={!(bankDetails && bankDetails.isValid())}
              size="small"
              label={t("rewards.amountToSend")}
              value={ibanAmount}
              onChange={handleIbanChange}
              type="number"
              onInput={e => {
                e.target.value = e.target.value < 0 ? 0 : e.target.value; // Negatif sayıları engeller
              }}
              helperText={
                rewardStats.available_reward_amount < ibanAmount
                  ? "Yetersiz puan!"
                  : ibanAmount < 100 && ibanAmount > 0
                    ? "En az 100 ve üzeri harcamalarda kullanılabilir"
                    : t("rewards.sendToIbanHelperText")
              }
              error={
                (ibanAmount && ibanAmount < 100) ||
                rewardStats.available_reward_amount < ibanAmount
              }
            />
            {bankDetails ? (
              bankDetails.isValid() ? (
                <Button
                  variant="contained"
                  sx={{mt: 2}}
                  disabled={
                    !ibanAmount ||
                    rewardStats.available_reward_amount < ibanAmount ||
                    ibanAmount < 100
                  }
                  onClick={() => {
                    handleOpenIbanModal();
                  }}
                >
                  {t("rewards.receiveGifts")}
                </Button>
              ) : (
                <Button
                  sx={{mt: 2}}
                  variant="contained"
                  onClick={handleNavigateToBankDetails}
                >
                  {t("rewards.enterBankDetails")}
                </Button>
              )
            ) : (
              <CircularProgress size={24} color="inherit" />
            )}
          </Box>
          {/* Modal */}
          <Modal open={isIbanModalOpen} onClose={handleCloseIbanModal}>
            <Box
              sx={{
                width: {xs: "90%", sm: "70%", md: 500},
                maxHeight: "90vh",
                overflowY: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                p: 3,
                borderRadius: 3,
                boxShadow: 24,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "primary.main",
                }}
              >
                IBAN Onayı
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                  textAlign: "center",
                  color: "text.secondary",
                }}
              >
                Lütfen aşağıdaki koşulları kabul ettiğinizden emin olun.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bankAccountValidationCheckBox}
                    onChange={e => setBankAccountValidationCheckBox(e.target.checked)}
                  />
                }
                label="Para göndermek istediğim banka hesabı bana aittir."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bankInfoValidationCheckBox}
                    onChange={e => setBankInfoValidationCheckBox(e.target.checked)}
                  />
                }
                label="Girilen bilgilerde hata olması durumunda işlemin gerçekleşmeyebileceğini ve bunun sonuçlarından sorumlu olacağımı anlıyorum."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={transactionAccountValidationCheckbox}
                    onChange={e =>
                      setTransactionAccountValidationCheckbox(e.target.checked)
                    }
                  />
                }
                label="Girdiğim IBAN numarası vadesiz banka hesabıdır. Bunun haricindeki yerlere (vadeli banka hesabı, PTT posta çeki hesabı, kredi kartı ve ATM kartı gibi) yapılan işlemlerin gerçekleşmeyeceğini anlıyorum."
              />
              <LoadingButton
                sx={{
                  mt: 3,
                  bgcolor: "primary.main",
                  color: "white",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
                loading={ibanButtonLoading}
                variant="contained"
                disabled={
                  !(
                    bankAccountValidationCheckBox &&
                    bankInfoValidationCheckBox &&
                    transactionAccountValidationCheckbox
                  )
                }
                onClick={() => {
                  handleIbanConfirm();
                }}
              >
                {t("rewards.receiveGifts")}
              </LoadingButton>
            </Box>
          </Modal>
          {/* Previous Reward Requests Section */}
          <Box sx={{mb: 2}}>
            <Typography variant="h6" sx={{width: "100%"}}>
              {t("rewards.previousRewardRequests")}
            </Typography>
            <Typography variant="body2">
              {t("rewards.previousRewardRequestsDescription")}
            </Typography>
          </Box>
          <TableContainer component={Paper}>
            {loading ? (
              <LinearProgress color="inherit" />
            ) : rewardRequests?.length === 0 ? (
              <Typography align="center" sx={{p: 2}}>
                {t("noDataFound")}
              </Typography>
            ) : isMobile2 ? (
              rewardRequests.map(request => (
                <GenericCard
                  key={request.id}
                  data={{
                    unExtendedData: [
                      {
                        title: t("rewards.requestedStatus"),
                        description: getRequestedStatusText(request.status),
                      },
                      {
                        title: t("rewards.pointUsed"),
                        description: `${request.amount} ${request.tax_amount ? `(${request.tax_amount} Vergi)` : ""}`,
                      },
                      {
                        title: t("rewards.rewardType"),
                        description: request.type,
                      },
                      {
                        title: t("rewards.giftCode"),
                        description: getGiftCode(request.type, request.code),
                      },
                      {
                        title: t("rewards.giftPin"),
                        description: getGiftPin(request.type, request.code),
                      },
                      {
                        title: t("rewards.message"),
                        description: request.message,
                      },
                      {
                        title: t("rewards.requestCreatedAt"),
                        description: datetimeToObject(request.created_at),
                      },
                      {
                        title: t("rewards.actions"),
                        specialDescription: (
                          <CancelRewardButtonAndDialog
                            disabled={request.status !== rewardRequestStatus.PENDING}
                            id={request.id}
                            handleRewardRequests={() =>
                              handleRewardRequests(rewardRequestPage)
                            }
                            getTeamMemberRewardsStats={() =>
                              teamMemberService
                                .getTeamMemberRewardsStats()
                                .then(stats => {
                                  setRewardStats(new TeamMemberRewardsStats(stats));
                                })
                            }
                          />
                        ),
                      },
                    ],
                  }}
                />
              ))
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("rewards.requestedStatus")}</TableCell>
                    <TableCell>{t("rewards.pointUsed")}</TableCell>
                    <TableCell>{t("rewards.rewardType")}</TableCell>
                    <TableCell>{t("rewards.giftCode")}</TableCell>
                    <TableCell>{t("rewards.giftPin")}</TableCell>
                    <TableCell>{t("rewards.message")}</TableCell>
                    <TableCell>{t("rewards.requestCreatedAt")}</TableCell>
                    <TableCell>{t("rewards.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rewardRequests.length > 0 ? (
                    rewardRequests.map((request, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div
                            style={{
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              backgroundColor: getBackgroundColor(request.status),
                              color: "white",
                              textAlign: "center",
                              cursor: "pointer",
                              fontWeight: "bold",
                              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={() => {
                              // Butona tıklama fonksiyonu buraya eklenebilir
                            }}
                          >
                            {getRequestedStatusText(request.status)}
                          </div>
                        </TableCell>

                        <TableCell>{`${request.amount} ${request.tax_amount ? `(${request.tax_amount} Vergi)` : ""}`}</TableCell>
                        <TableCell>{request.type}</TableCell>
                        <TableCell>{getGiftCode(request.type, request.code)}</TableCell>
                        <TableCell>{getGiftPin(request.type, request.code)}</TableCell>
                        <TableCell>
                          {request.message ? (
                            <div
                              style={{
                                display: "inline-block",
                                padding: 5,
                                borderRadius: "5px",
                                backgroundColor: "rgba(0, 0, 0, 0.05)",
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              {request.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>{datetimeToObject(request.created_at)}</TableCell>
                        <TableCell>
                          <CancelRewardButtonAndDialog
                            disabled={request.status !== rewardRequestStatus.PENDING}
                            id={request.id}
                            handleRewardRequests={() =>
                              handleRewardRequests(rewardRequestPage)
                            }
                            getTeamMemberRewardsStats={() =>
                              teamMemberService
                                .getTeamMemberRewardsStats()
                                .then(stats => {
                                  setRewardStats(new TeamMemberRewardsStats(stats));
                                })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        {t("rewards.noRequests")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            <GenericTablePagination
              page={rewardRequestPage - 1}
              rowsPerPage={10}
              count={totalRewardRequestCount}
              onPageChange={newPage => {
                handleRewardRequests(newPage + 1);
              }}
              rowsPerPageOptions={[10]}
            />
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
};
