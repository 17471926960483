export const enTranslations = {
  mySurveys: "My Surveys",
  myCompletedSurvey: "My Completed Survey",
  totalRewardsReceived: "Total Rewards Received",
  openSurveys: "Open Surveys",
  accountInfo: "Account Info",
  detailedInformation: "Detailed Information",
  minute: "min",
  mainPage: {
    howToUse: "How to use our platform?",
    inNumbers: "In Numbers",
    register: "Register",
    fillInSurveys: "Fill In Surveys",
    winPrize: "Win Prize",
    registerDescr:
      "As a first step, register on our platform and get access to the surveys inside.",
    fillInSurveysDescr:
      "Log in to the platform and fill out the survey that interests you.",
    winPrizeDescr:
      "Earn various rewards as you complete surveys. Rewards can include discounts from different brands, cash and more.",
    participantPool: "Participant Pool",
    surveyDiversity: "Survey Diversity",
    rewardDiversity: "Reward Diversity",
    support: "Support",
    participantPoolDescr: "Many people we can assign to your surveys",
    surveyDiversityDescr: "Too many draft surveys",
    rewardDiversityDescr: "Gift cards, discounts, cash prizes and more you can win",
    supportDescr: "Continuous support for your surveys",
    FAQ: "FAQ",
  },
  faq: {
    faqTitle: "Frequently Asked Questions",
    faqDescr:
      "If you cannot find the answer to the question you are looking for, you can contact us.",
    accountOperations: "Account Operations",
    surveys: "Surveys",
    giftCodes: "Gift Codes",
    howToUseGiftCodes: "How to Use Gift Codes?",
    giftCodeDescr:
      "Please read the following instructions carefully before using your gift codes.",
    multipleAccount: "Can I Open More Than One Membership Account?",
    changePassword: "How Can I Change My Password?",
    notificationPreferences: "How Can I Change My Notification Preferences?",
    paymentInformation: "How Can I Update My Payment Receiving Information?",
    whoCanParticipate: "Who Can Participate in Surveys?",
    howCanIBeInformed: "How Can I Be Informed About New Surveys and Opportunities?",
    canIFillMobileDevice: "Can I Complete Surveys from a Mobile Device?",
    howCanIUseGiftCodes: "How Can I Use Gift Codes?",
    validityPeriod: "What Is the Validity Period of My Gift Codes?",
    lostGiftCode: "I Lost My Gift Code, What Should I Do?",

    answMultipleAccount:
      "We offer a unique account for each user. This ensures that all your earnings and gift points accumulate securely in a single account. If multiple accounts are created, the system will detect it, and unfortunately, it will not be possible to preserve the accumulated points.",
    answChangePassword:
      "You can update your password from your account settings. If you have forgotten your password, you can use the 'Forgot Password' option on the login page.",
    answNotificationPreferences:
      "You can customize your email and other notification preferences from your account settings. We recommend keeping your notifications enabled to stay informed about new surveys, rewards, and special offers.",
    answPaymentInformation:
      "You can update your bank account information from the 'Payment Information' section in account settings. For security purposes, every update requires SMS confirmation.",
    answWhoCanParticipate:
      "All our users who are registered in the system and are over the age of 18 can participate in surveys. Some surveys may be tailored to participants with specific demographic characteristics.",
    answHowCanIBeInformed:
      "To stay informed about new surveys: Go to 'Account Information > Communication Preferences' and keep your notifications enabled, subscribe to our email newsletter, and follow our social media accounts (Instagram, Twitter, Facebook).",
    answCanIFillMobileDevice:
      "Yes, you can easily complete our surveys on mobile devices (phone and tablet).",
    answHowCanIUseGiftCodes:
      "You can use your gift codes at partner stores or online platforms. For a detailed usage guide, please visit the 'How to Use Gift Codes' page.",
    answValidityPeriod:
      "The validity period of gift codes may vary depending on the store. You can check the validity of your code in the 'My Account > My Gift Codes' section.",
    answLostGiftCode:
      "You can view all your active codes in the 'My Account > My Gift Codes' section. If you experience any issues, please contact us.",
  },

  rewards: {
    actions: "Actions",
    rewards: "Rewards",
    availablePoints: "Available Points",
    usedPoints: "Used Points",
    amount: "Amount: ",
    giftCard: "Hediye Kartı",
    giftCardDescription:
      "Gift cards (vouchers) are used by companies as a single code for each purchase. Therefore, we recommend requesting them in bulk.",
    giftCardTaxDescription:
      "For gift cards (vouchers), 1 point is equivalent to 1 TL. For cash requests, however, due to the 10% withholding tax imposed by the government, 1 point is worth 0.9 TL.",
    giftLimitDescription:
      "The lower limit is 50 points for gift vouchers and 100 points for bank transfers.",
    receiveGifts: "Receive Gifts",
    sendToIban: "Bank Transfer",
    sendToIbanHelperText: "There is a 10% withholding tax deduction on cash transfers.",
    amountToSend: "Point to use",
    totalSendAmount: "Total send amount:",
    bulkRequestPromotion:
      "Request in bulk and earn more! For 100 or more requests, we give an extra 10 TL.",
    enterBankDetails: "Please Enter Your Bank Details",
    previousRewardRequests: "Previous Reward Requests",
    previousRewardRequestsDescription:
      "Your reward will be defined on this screen within 5 business days at the latest.",
    requestedDate: "Requested Date",
    surveyName: "Survey Name",
    requestedStatus: "	Requested Status",
    giftPin: "Gift Pin",
    processing: "Processing",
    approved: "Approved",
    rejected: "Rejected",
    cancelled: "Cancelled",
    notRequired: "Not Required",
    pointUsed: "Point Used",
    rewardType: "Reward Type",
    rewardSentDate: "Reward Sent Date",
    giftCode: "Gift Code",
    noRequests: "No reward requests submitted yet.",
    pendingPoints: "Pending Points",
    cancelRequest: "Cancel Request",
    rewardCancelConfirmation: "Are you sure you want to cancel this reward request?",
    rewardCancelConfirmationDescription:
      "Once cancelled, you won't be able to undo this action.",
    cancel: "Cancel",
    confirmCancel: "Yes, Cancel",
    rewardCancelledSuccess: "Your reward request has been cancelled successfully!",
    rewardCancelledError: "There was an error while cancelling your reward request.",
    requestCreatedAt: "Request Date",
    message: "Message",
  },
  completedSurveys: {
    completedSurveys: "Completed Events",
    surveyName: "Survey Name",
    completedDate: "Completed Date",
    rewardType: "Reward Type",
    surveyStatus: "Survey Status",
    rewardStatus: "Reward Status",
    defined: "Defined",
    undefined: "Undefined",
    rewardAccess: "Reward Access",
    rewardAmount: "Reward Amount",

    ongoingStatus: "Ongoing",
    completedStatus: "Completed",
  },

  tableHeaders: {
    title: "Title",
    questions: "Questions",
    responses: "Responses",
    completion: "Completion",
    created: "Created",
    updated: "Updated",
    actions: "Actions",
  },
  surveyFeature: {
    duration: "Duration",
    rewardPoint: "Reward Point:",
  },
  prev: "Previous Button",
  next: "Next Button",
  startButton: "Start",
  noDataFound: "No data found",
  settings: {
    surveyTitle: "Survey Title",
    live: "Live",
  },
  buttons: {
    downloadDraftExcel: "Draft Excel File",
    UploadDraftExcel: "Edited Excel File",
    yourDraftExcel: "Your Excel File",
  },
  importExcelButtonLabel: "Survey From Excel File",
  importExcelDialogTitle: "Survey From Excel File",
  importExcelNameLabel: "Survey Name",
  importFileButtonLabel: "Import File",
  createSurveyButtonLabel: "Create",
  createSurveyDialogTitle: "Create a survey from scratch",
  nameInputLabel: "Name",
  createButtonLabel: "Create",
  cancelButtonLabel: "Back",
  copyLink: "Copy Link",
  copySurvey: "Copy Survey",
  delete: "Delete",
  deleteSurveyTitle: "Delete Survey",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} survey will be deleted permanently. Are you sure?",

  cancel: "Cancel",
  sections: {
    survey: "Survey",
    preview: "Preview",
    distributions: "Distributions",
    results: "Results",
    analyticsDashboard: "Analytics Dashboard",
  },
  basicSettings: {
    title: "Basic Settings",
    language: "Language",
    languageDescription:
      "We recommend selecting the language you wrote your survey in. Your response data will also be displayed in this language.",
    previousButton: "Previous Button",
    previousButtonDescription: "Enable/Disable previous button.",
    ipBlock: "IP Block",
    ipBlockDescription: "Block multiple responses from the same IP.",
  },

  writeSomething: "Write something",
  unavailableSurveyMessage: "Unavailable Survey Message",
  buttonLabels: {
    update: "Update",
    reset: "Reset",
  },
  blockSettings: {
    editBlock: "Edit Block",
    displayLogicWarning:
      "Attempting to use display logic and randomization within the same block. This combination may not function as expected.",
    randomizeQuestions: "Randomize Questions",
    addPageBreak: "Add Page Break to all Questions",
    makeAllRequired: "Make All Questions Required",
  },
  none: "None",
  male: "Male",
  female: "Female",
  other: "Other",
  all: "All",

  editInfo: {
    fullName: "Fullname",
    editInfo: "Edit Info",
    mail: "Email (info@galataanket.com)",
    phoneNumber: "Phone number (0532 000 00 00)",
    gender: "Gender",
    birthday: "Birthday",
    country: "Which country do you live in?",
    country2: "Which country?",
    province: "Which city?",
    editAccountInformation: "Edit Account Information",
    saveAccountInformation: "Save Account Information",
    accountInformation: "Account Information",
    accountInformationDesc:
      "Manage your personal information such as name, email, and contact details in this section.",

    changePassword: "Change Password",
    changePasswordDesc: "Update your account password to ensure your account's security.",

    cancel: "Cancel",
    delete: "Delete",
    changeCommunicationPreferences: "Change Communication Preferences",
    communicationPreferences: "Change Communication Preferences",
    communicationPreferencesDesc:
      "Set your notification and communication preferences here, including email and SMS settings.",

    communicationPreferencesDirective:
      "Control how we communicate with you. Enable or disable notifications as you prefer.",
    receiveEmailNotification: "Receive notifications via email.",
    receiveSMSNotification: "Receive notifications via SMS.",
    receiveOurUpdates: "Receive our latest news and updates.",

    emailNotifications: "Email Notifications",
    SMSNotifications: "SMS Notifications",
    newsletterSubscription: "Newsletter Subscription",

    changesWillBeSaveAutomatically:
      "Changes will be save automatically as you toggle your preferences.",

    paymentInformation: "Payment Information",
    paymentInformationDesc:
      "View and manage your payment methods, billing details, and transaction history.",

    deleteAccountConfirmation: "Are you sure you want to delete your account?",
    deleteAccountConfirmationDescription:
      "Deleting your account is irreversible. All your data will be permanently removed. Are you sure you want to proceed?",
    deleteAccount: "Delete Account",
    changePasswordConfirmation: "Are you sure you want to change your password?",
    changePasswordConfirmationDescription:
      "Changing your password will require you to log in again. Are you sure you want to proceed?",
    identityNumber: "Identity Number",
    address: "Address",
    addressMin: "Please provide your full address.",
    birthdayDidNotChange: "Date of birth information cannot be changed later.",
  },
  paymentInfo: {
    paymentAlert:
      "In order to send money to the bank account, the following information must be filled in.",
    bankName: "Bank Name",
    ibanConfirmation: " Iban Confirmation (TRXXXXXXXXXXXXXXXXXXXXXX)",
  },
  editor: {
    endPageLabel: "End Page",
    createBlockTooltip: "Create Question Block",
    editBlockTooltip: "Edit Question Block",
    deleteBlockTooltip: "Delete Question Block",
    blockDeletionConfirmation: "block will be permanently deleted. Are you sure?",
  },
  downloadResults: "Download Results",
  resetSurvey: "Reset Survey",
  questionLabel: "Question Label",
  duplicate: "Duplicate",
  shuffleQuestion: "Shuffle Question",
  deleteQuestion: "Delete Question",
  ignoreRequire: "Ignore Require",
  ignorePreviewData: "Ignore Preview Data",
  addOption: "Add Option",

  label: "Default Question Label",
  choice1: "Choice 1",
  choice2: "Choice 2",
  choice3: "Choice 3",
  defaultQuestion: "Default Question Title",
  save: "Save",
  reset: "Reset",

  EditQuestion: "Edit Question",
  QuestionType: "Question Type",
  Text: "Text",
  Settings: "Settings",
  Behaviours: "Behaviours",
  MultipleChoice: "Multiple Choice",
  ShortText: "Short Text",
  TextGraphic: "Text / Graphic",
  SurveyQuestion: "Survey Question",
  EmbeddedDataField: "Embedded Data Field",
  EditQuestionTextDialogTitle: "Edit Question Text",
  PlaceholderWriteSomething: "Write something",
  CancelButton: "Cancel",

  SaveButton: "Save",
  RequiredLabel: "Required",
  requiredField: "This field must be filled.",
  ContentTypeLabel: "Content Type",
  TextLabel: "Text",
  NumberLabel: "Number",
  EmailAddressLabel: "Email Address",
  RequiredQuestionError: "This question is required.",
  textInputTypeError: "Text input is invalid.",
  integerInputTypeError: "Number input is invalid.",
  emailInputTypeError: "Email input is invalid.",
  DisplayLogic: "Display Logic",
  DownloadQRCode: "Download QR Code",
  CopyLink: "Copy Link",
  AddElement: "Add Element",
  Link: "Link",
  QRCode: "QR Code",
  CreateQuestion: "Create Question",
  AddPageBreak: "+ Add Page Break",
  LogoutButton: "Logout",
  AddBlock: "Add Block",
  AddBranch: "Add Branch",
  successfullyUpdated: "Successfully Updated",

  builder: "Builder",
  surveyFlow: "Survey flow",
  surveyOptions: "Survey options",
  enabled: "Enabled",

  auth: {
    login: "Log in",
    register: "Register",
    registerHelp:
      "We will send you a link via SMS to register. If you encounter any issues, please email <email>anket@galataanket.com</email>.",
    emailIsRequired: "Please enter your email address.",
    phoneIsRequired: "Please enter your phone number",
    surnameIsRequired: "Please enter your surname",
    nameIsRequired: "Please enter your name",
    messageIsRequired: "Please enter your message",
    passwordIsRequired: "Please enter your password",
    alreadyhaveaccount: "Already Have Account",
    mustBeValidEmail: "Must be a valid email",
    emailAddressOrPhoneNumber: "Phone Number",
    emailOrPassIncorrect: "Phone number or password is incorrect, Please try again.",
    enterValidEmailAddress: "Please enter a valid phone number.",
    changePassword: {
      oldPassword: "Old Password",
      newPasswordSection: "New Password Section",
      oldPasswordRequired: "Old password is required.",
      passwordRequired: "Password is required.",
      passwordMinLength: "Password must be at least 8 characters long.",
      reEnterPasswordRequired: "Please re-enter your password.",
      passwordsNotMatch: "Passwords do not match.",
    },

    editInfo: {
      bank_full_name: "Full Name",
      fullNameRequired: "Full name is required.",
      bank_identity_number: "Identity Number",
      identityNumberRequired: "Identity number is required.",
      invalidIdentityNumber: "Invalid Turkish ID.",
      bank_address: "Please Enter Your Full and Complete Address.",
      addressRequired: "Address is required.",
      editAccountInformation: "Edit Account Information",
      saveAccountInformation: "Save Account Information",
    },
    paymentInfo: {
      paymentAlert: "Please provide your billing information below.",
      bank_name: "Bank Name",
      bankNameRequired: "Bank name is required.",
      ibanRequired: "IBAN is required.",
      invalidIban: "Invalid IBAN format.",
      ibanConfirmation: "Confirm IBAN",
      ibanConfirmationRequired: "IBAN confirmation is required.",
      ibansNotMatch: "IBANs do not match.",
      city: "City",
      neighborhood: "Neighborhood",
      street: "Street",
      building: "Building Number",
      apartment: "Apartment",
      postalCode: "Postal Code",
      cityRequired: "City is required.",
      neighborhoodRequired: "Neighborhood is required.",
      streetRequired: "Street is required.",
      buildingRequired: "Building is required.",
      apartmentRequired: "Apartment is required.",
      postalCodeRequired: "Postal code is required.",
      invalidPostalCode: "Invalid postal code.",
    },

    resetPassword: {
      title: "Reset Password",
      textLabel: "Phone Number",
      buttonLabel: "Send activation link",
      invalidInputMessage: "Please provide a valid phone number (05329993377).",
      confirmationMessage:
        "If your info is in the system, you'll get an activation SMS or E-Mail. \nCheck your phone. \nFor questions, contact katilimcihavuzu@galataanket.com.",
    },
    forgotpasswordtitle: "Renew Password",
    forgotpasswordlink: "Sent a new password",
    emailAddress: "Email Adress",
    phoneNumber: "Phone Number",
    password: "Password",
    Login: "Login",
    continue: "Send activation link",
    sendnewpassword: "Send a new password",
    sentinfo:
      "If your info isn't in the system, you'll get an activation SMS. \nCheck your phone. \nFor questions, contact katilimcihavuzu@galataanket.com.",
    passwordsentinfo:
      "If your info isn't in the system, you'll get a new password. You can change it from account settings again. For questions, contact katilimcihavuzu@galataanket.com.",
    forgotPassword: "Forgot password?",
    noAccount: "Don't have an account?",
    alreadyhaveaccount: "If you already activated your account:",
    reEnterPassword: "Re-enter Password",
    passwordsNotMatch: "Passwords do not match",
    returnToHomepage: "Return To Homepage",
  },
  ComingSoon: "Coming Soon",
  companyDescription:
    "Galata Anket is an academic and market research company that integrates digital survey tools and provides data-driven consultancy to its clients.",
  platformTour: "Platform Tour",

  professionalSurveyTeam: "A professional team for the most reliable survey experience!",
  dedicatedResearchAndAnalysis:
    "Our team of expert researchers and data analysts works diligently to design and manage surveys tailored to each customer's specific needs and objectives. Our aim is to gather accurate, reliable, and actionable data through online surveys or focus groups.",
  notOnlyDataCollection: "More than just data!",
  clientReportingAndRecommendations:
    "In addition to providing our customers with detailed reports and analyses, we offer alternative suggestions on how they can utilize the compiled information to enhance their organizations and bring them closer to their goals. Furthermore, we present the most accurate planning for implementing these suggestions.",
  dataDrivenDecisions:
    "Growth with Feedback: Stand Out in Competition with the Voice of Customers",
  commitmentToClientSuccess:
    "We strive to make our customers more visible in the competitive market and enable them to hear their own voice of customers more clearly by providing them with high-quality data. Join our mission and discover the power of listening to your customers' feedback!",
  messageSent: "Your message has been sent!",
  messageSendError: "Failed to send message. Please try again later.",
  welcomeContactPage: "Welcome to Our Contact Page!",
  contactInvitation:
    "Feel free to reach out to us with any questions or comments. We’d love to hear from you!",
  contactUs: "Contact Us",
  name: "Name",
  surname: "Surname",
  mail: "Mail",
  message: "Message",
  sending: "Sending ...",
  sendMessage: "Send Message",
  needHelp: "Need Help?",
  home: "Home",
  aboutUs: "About Us",
  contact: "Contact",
  forResearchers: "For Researchers",
  platform: "Platform",
  pages: "Pages",
  websiteDisclosure: "Website Disclosure Text",
  cookiePrivacyPolicy: "Cookie and Privacy Policy",
  dataSubjectApplicationForm: "Data Subject Application Form",
  ourAddress: "Our Address",
  followUs: "Follow Us",
  rightsReserved: "Galata Survey. All rights reserved.",
  somethingWentWrong: "Something went wrong. Please try again later.",
  serverErrorMessage:
    "We are currently unable to reach the content you requested. Please try again later.",
  generic: {
    genericCard: {
      showMore: "Show More",
      showLess: "Show Less",
    },
    genericTablePagination: {
      of: "of",
      page: "Page",
      noRecords: "No records",
      previousPage: "Previous page",
      nextPage: "Next page",
    },
  },
  forResearchersPage: {
    firstnameRequired: "Please enter your name.",
    surnameRequired: "Please enter your surname.",
    onlyLetters: "Only letters are allowed.",
    emailRequired: "Please enter your email address.",
    subjectRequired: "Please enter a subject.",
    summaryRequired: "Please enter a summary.",
    targetAudienceRequired: "Please enter a target audience.",
    numberRequired: "Please enter a number.",
    participantCountRequired: "Please enter the number of participants.",
    questionCountRequired: "Please enter the number of questions.",
    plannedDateRequired: "Please enter a planned date.",

    contactTitle: "Connect Your Research with Participants",
    description:
      "Conduct your academic or market research quickly and reliably by reaching thousands of participants of all age groups across Turkey.",
    step1Title: "Define Target Audience",
    step1Description: "Filter your participants based on demographic characteristics.",
    step2Title: "Fast Access",
    step2Description: "Reach hundreds of participants within a few days.",
    step3Title: "Reliable Data",
    step3Description: "Collect high-quality data from verified participants.",
    step4Title: "Suitable Dynamic Pricing",
    step4Description:
      "Pay only the necessary amount based on your target audience and survey length.",

    shareInfo: "Share Your Information",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email Address",
    researchSubject: "Research Topic",
    researchSummary: "Research Summary",
    targetAudience: "Target Audience",
    targetParticipants: "Target Number of Participants",
    plannedQuestions: "Planned Number of Questions",
    plannedDate: "Planned Date",
    additionalDetails: "Additional Details You Want to Share",
    referenceEmail: "Reference Email",
    sending: "Sending...",
    submitInfo: "Submit Information",
    moreInfo: "For more information",
    moreQuestions: "For more questions",
    contactEmail: "arastirma@galataanket.com",
  },
};
