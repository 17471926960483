import {rewardRequestStatus} from "src/schemas/project/reward";

export function isNonEmptyString(value) {
  return typeof value === "string" && value.trim().length > 0;
}

export const getBackgroundColor = status => {
  switch (status) {
    case rewardRequestStatus.APPROVED:
      return "rgba(76, 175, 80)";
    case rewardRequestStatus.PENDING:
      return "rgba(255, 193, 7)";
    case rewardRequestStatus.REJECTED:
      return "rgba(244, 67, 54)";
    default:
      return "rgba(244, 67, 54, 0.8)";
  }
};
